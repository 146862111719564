import { createTheme } from '@mui/material/styles'
import { red, grey } from '@mui/material/colors'

export default createTheme({
  palette: {
    secondary: {
      light: red[400],
      main: red[600],
      dark: red[800],
      contrastText: '#fff'
    },
    dark: {
      main: '#233044',
      light: grey[300],
      lighter: grey[200],
      dark: grey[900]
    },
    light: {
      main: '#f7f9fc'
    }
  },
  typography: {
    fontFamily: ['"inter"', '"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
    fontSize: 11.5
  }
})
