import { Link } from 'react-router-dom'
import { Typography, Card, CardContent } from '@mui/material'
import { Box } from '@mui/system'

export default function LinkCard({ icon, text, color, buttonLink, state = {}, notice = {} }) {
  return (
    <Card
      component={Link}
      to={buttonLink}
      state={state}
      variant="outlined"
      sx={{
        width: 1,
        minWidth: 150,
        minHeight: 130,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textDecoration: 'none',
        transition: 'all 0.3s ease-in-out',
        '& .icon-wrapper': {
          border: '5px solid',
          borderColor: color,
          borderRadius: '50%',
          p: '10px',
          width: 70,
          height: 70,
          transition: 'all 0.3s ease-in-out'
        },
        '& .MuiSvgIcon-root': {
          fill: color,
          transition: 'all 0.3s ease-in-out'
        },
        '& .notice': {
          color: notice.color,
          transition: 'all 0.3s ease-in-out'
        },
        '&:hover': {
          color: (theme) => theme.palette.common.white,
          backgroundColor: color,
          '& .icon-wrapper': {
            borderColor: (theme) => theme.palette.common.white
          },
          '& .MuiSvgIcon-root': {
            fill: (theme) => theme.palette.common.white
          },
          '& .notice': {
            color: (theme) => theme.palette.common.white
          }
        }
      }}
    >
      <CardContent sx={{ width: 1, display: 'flex', flexFlow: 'row wrap', alignItems: 'center', pl: 3, py: 1, '&:last-child': { pb: 1 } }}>
        <div className="icon-wrapper">{icon}</div>
        <Box component="span" sx={{ ml: 2 }}>
          <Typography variant="h5" component="div" dangerouslySetInnerHTML={{ __html: text }} />
          <Typography variant="body1" component="div" className="notice">
            {notice.text}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  )
}
