import { Bungalow, Search, AppRegistration, Support } from '@mui/icons-material'

export const sideNavs = [
  {
    text: 'Home',
    path: '/',
    icon: <Bungalow sx={{ fontSize: 25 }} />,
    state: {}
  },
  {
    text: 'Search',
    icon: <Search sx={{ fontSize: 25 }} />,
    children: [
      {
        text: 'Individual Search',
        path: '/individual-search',
        state: { title: 'Individual Search' }
      },
      {
        text: 'Company Search',
        path: '/company-search',
        state: { title: 'Company Search' }
      },
      {
        text: 'Batch Search',
        path: '/batch-search',
        state: { title: 'Batch Search' }
      }
    ]
  },
  {
    text: 'Registry',
    icon: <AppRegistration sx={{ fontSize: 25 }} />,
    authority: ['R'],
    children: [
      {
        text: 'By Individual',
        path: '/registry-by-individual',
        state: { title: 'Registry By Individual' }
      },
      {
        text: 'By Company',
        path: '/registry-by-company',
        state: { title: 'Registry By Company' }
      }
    ]
  },
  {
    text: 'Help and Support',
    icon: <Support sx={{ fontSize: 25 }} />,
    children: [
      {
        text: 'Contact us',
        path: '/contact-us',
        state: { title: 'Contact us' }
      },
      {
        text: 'FAQ',
        path: '/faq',
        state: { title: 'REDX FAQs' }
      }
    ]
  }
]
