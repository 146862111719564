import { createRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ReCAPTCHA from 'react-google-recaptcha'
import { Paper, Button, Box } from '@mui/material'
import { RECAPTCHA_SITE_KEY } from '../consts'
import useToken from '../Hooks/useToken'
import { RedxTextField } from '../Components/RedxFormFields'
import { validateEmail } from '../Hooks/useFormValidation'
import { contactUs } from '../Services/user'

export default function ContactUs() {
  const { state } = useLocation()
  const recaptchaRef = createRef()
  const [enableSubmit, setEnableSubmit] = useState(false)
  const { name = '', sub = '' } = useToken()

  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: {
      name,
      email: sub,
      message: state?.message || '',
      additionalMessage: ''
    }
  })

  const handleFormReset = () => {
    recaptchaRef.current.reset()
    setEnableSubmit(false)
    reset()
  }

  const onSubmitForm = async (values) => {
    try {
      await contactUs({
        ...values,
        message: `${values.message} ${values.additionalMessage}`
      })
      handleFormReset()
    } catch (error) {
      console.log('error', error)
    }
  }

  const onVerifyReCaptcha = () => {
    setEnableSubmit(true)
  }

  return (
    <Paper variant="outlined" square sx={{ p: 3, flex: 1 }}>
      <Box
        component="form"
        sx={{
          maxWidth: 500,
          '& .MuiTextField-root': { my: 1, width: 1 }
        }}
        noValidate
        autoComplete="off"
      >
        <RedxTextField
          InputProps={{
            readOnly: true
          }}
          required
          label="Full Name"
          name="name"
          control={control}
          rules={{ required: 'Name is required' }}
        />
        <RedxTextField
          InputProps={{
            readOnly: true
          }}
          required
          type="email"
          label="Email Address"
          name="email"
          control={control}
          rules={{
            required: 'Email required',
            validate: (value) => validateEmail(value) || 'Email is not valid'
          }}
        />

        <RedxTextField
          InputProps={{
            readOnly: !!state?.persistantMessage
          }}
          required
          multiline
          minRows={5}
          maxRows={10}
          label="Message"
          name="message"
          control={control}
          rules={{ required: 'Message is required' }}
        />

        {state?.additionalMessage && (
          <RedxTextField multiline minRows={5} maxRows={10} label={state?.additionalMessage} name="additionalMessage" control={control} />
        )}

        <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} onChange={onVerifyReCaptcha} />

        <Button color="secondary" disabled={!enableSubmit} onClick={handleSubmit(onSubmitForm)} variant="contained" sx={{ mt: 1 }}>
          Submit
        </Button>
        <Button onClick={handleFormReset} variant="outlined" sx={{ mt: 1, ml: 1 }}>
          Clear
        </Button>
      </Box>
    </Paper>
  )
}
