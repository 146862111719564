import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Alert } from '@mui/material'
import { useForm } from 'react-hook-form'
import { RedxPasswordField } from '../Components/RedxFormFields'
import { changePassword } from '../Services/user'

export default function ChangePassword() {
  const { handleSubmit, control, trigger, getValues, reset } = useForm({
    mode: 'all',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    }
  })

  const [updateStatus, setUpdateStatus] = useState({ show: false })
  const handleFormReset = () => {
    reset()
  }

  const onSubmitForm = async ({ oldPassword, newPassword }) => {
    try {
      const {
        data: { message }
      } = await changePassword({
        oldPassword,
        newPassword
      })
      setUpdateStatus({
        show: true,
        severity: 'success',
        message
      })
      handleFormReset()
    } catch (error) {
      const { message } = error.response.data.error
      setUpdateStatus({
        show: true,
        severity: 'error',
        message
      })
    }
  }

  const navigate = useNavigate()
  const handleCancel = () => {
    navigate(-1)
  }

  return (
    <Box
      component="form"
      sx={{
        maxWidth: 500,
        '& .MuiTextField-root': { my: 1, width: 1 }
      }}
      noValidate
      autoComplete="off"
    >
      <RedxPasswordField required label="Old Password" name="oldPassword" control={control} rules={{ required: 'Old password required' }} />
      <RedxPasswordField
        required
        label="New Password"
        name="newPassword"
        control={control}
        rules={{
          required: 'New password required',
          validate: () => {
            trigger('confirmPassword')
            return true
          },
          minLength: {
            value: 6,
            message: 'Minimum 6 characters required'
          }
        }}
      />
      <RedxPasswordField
        required
        label="Confirm Password"
        name="confirmPassword"
        type="password"
        control={control}
        rules={{
          required: 'Confirm password required',
          validate: (value) => {
            if (value === getValues()['newPassword']) {
              return true
            } else {
              return 'Passwords do not match'
            }
          }
        }}
      />

      <Button type="submit" color="secondary" onClick={handleSubmit(onSubmitForm)} variant="contained" sx={{ mt: 1 }}>
        Change Password
      </Button>
      <Button color="primary" onClick={handleCancel} variant="outlined" sx={{ mt: 1, ml: 1 }}>
        Cancel
      </Button>

      {!!updateStatus.show && (
        <Alert sx={{ mt: 4 }} severity={updateStatus.severity} onClose={() => setUpdateStatus({ show: false })}>
          {updateStatus.message}
        </Alert>
      )}
    </Box>
  )
}
