import { useNavigate } from 'react-router-dom'
import { Button, Box, Typography } from '@mui/material'

export default function NotFoundPage() {
  const navigate = useNavigate()

  return (
    <Box sx={{ height: 1, display: 'flex', flexFlow: 'column wrap', justifyContent: 'center', alignItems: 'center' }}>
      <Typography component="p" sx={{ pb: 2, fontSize: '1rem' }}>
        Page you are looking for is not available.
      </Typography>
      <Button color="secondary" variant="outlined" onClick={() => navigate(-1)}>
        Go Back
      </Button>
    </Box>
  )
}
