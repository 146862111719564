import { useState } from 'react'
import { IconButton, Menu, MenuItem, MenuList, ListSubheader, ListItemText, ListItemIcon, Divider } from '@mui/material'

export default function IconMenu({ menuId, icon, menus }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        size="small"
        id={menuId + '-button'}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="inherit"
      >
        {icon}
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `${menuId}-button`
        }}
        sx={{ '& .MuiListSubheader-root': { lineHeight: '34px' } }}
      >
        {menus.map(({ groupLabel, items }, i) => (
          <MenuList key={i} subheader={<ListSubheader component="div">{groupLabel}</ListSubheader>}>
            <Divider />
            {items.map(({ icon, name, handler }, j) => (
              <MenuItem key={name + j} onClick={handler}>
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText>{name}</ListItemText>
              </MenuItem>
            ))}
          </MenuList>
        ))}
      </Menu>
    </div>
  )
}
