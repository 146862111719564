import * as React from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { SwipeableDrawer, List, IconButton, useMediaQuery } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ListItemLink from './ListItemLink'
import ListItemCollapsible from './ListItemCollapsible'
import useToken from '../Hooks/useToken'
import { sideNavs } from '../Assets/links'
import redexLogo from '../Assets/Images/redx-logo.png'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

export default function Sidebar({ open, onOpen, onClose, width, handler }) {
  const token = useToken()
  const { authorities = [] } = token || {}
  const filteredNavLink = () => {
    return sideNavs.filter((nav) => {
      if (nav.authority) {
        return authorities.some((a) => nav.authority.some((nA) => nA?.toLowerCase() === a?.toLowerCase()))
      }
      return true
    })
  }

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <SwipeableDrawer
      sx={{
        width: width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: width,
          boxSizing: 'border-box',
          backgroundColor: (theme) => theme.palette.common.white,
          color: (theme) => theme.palette.dark.dark
        }
      }}
      variant={mdUp ? 'persistent' : 'temporary'}
      anchor="left"
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    >
      <DrawerHeader sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
        <Link to="/" style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
          <img src={redexLogo} style={{ maxWidth: '100%', maxHeight: 60 }} />
        </Link>
        <IconButton onClick={handler} color="secondary">
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>

      <List component="nav">
        {filteredNavLink().map(({ text, icon, children, path, state }, i) =>
          children?.length ? (
            <ListItemCollapsible key={i} icon={icon} primary={text} subLink={children} />
          ) : (
            <ListItemLink key={i} icon={icon} primary={text} to={path} state={state} divider />
          )
        )}
      </List>
    </SwipeableDrawer>
  )
}
