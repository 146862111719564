import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { TextField, InputAdornment, IconButton, Box } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateRangePicker from '@mui/lab/DateRangePicker'
import addMonths from 'date-fns/addMonths'
import AdapterDateFns from '@mui/lab/AdapterDateFns'

export const RedxTextField = ({ name, control, label, rules = {}, helperText, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <TextField
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          label={label}
          {...rest}
          error={!!error}
          helperText={error ? error.message : helperText}
        />
      )}
      rules={rules}
    />
  )
}

export const RedxFileUploader = ({ name, control, label, rules = {}, helperText, ...rest }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur }, fieldState: { error } }) => (
        <TextField
          onChange={(e) => onChange(e.target.files)}
          onBlur={onBlur}
          label={label}
          {...rest}
          error={!!error}
          helperText={error ? error.message : helperText}
        />
      )}
      rules={rules}
    />
  )
}

export const RedxPasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <RedxTextField
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(event) => event.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

export const RedxDateRangePicker = (props) => {
  const getMonthsAfter = (date, amount) => {
    return date ? addMonths(date, amount) : undefined
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        {...props}
        startText="From"
        endText="To"
        disableFuture
        maxDate={getMonthsAfter(props.value[0], 3)}
        renderInput={(startProps, endProps) => (
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'row wrap',
              gap: '10px'
            }}
          >
            <TextField {...startProps} error={!props.value[0]} helperText={!props.value[0] && 'Start date is required'} />
            <TextField
              {...endProps}
              error={!props.value[1]}
              helperText={!props.value[1] && 'End date is required (within 3 months of the start date)'}
            />
          </Box>
        )}
      />
    </LocalizationProvider>
  )
}
