import { useNavigate, useSearchParams } from 'react-router-dom'
import { Paper, Button, Typography } from '@mui/material'
import { useForm } from 'react-hook-form'
import { RedxPasswordField } from '../Components/RedxFormFields'
import { resetPassword } from '../Services/auth'

export default function ResetPassword() {
  const navigate = useNavigate()

  const { handleSubmit, control, trigger, getValues } = useForm({
    mode: 'all',
    defaultValues: {
      newPassword: '',
      confirmPassword: ''
    }
  })

  let [searchParams] = useSearchParams()
  const onSubmitForm = async ({ newPassword }) => {
    try {
      await resetPassword({
        password: newPassword,
        forgotPasswordHash: searchParams.get('rs')
      })
      navigate('/login')
    } catch (error) {
      console.log('forgot Password failed', error)
    }
  }

  return (
    <Paper
      component={'form'}
      autoComplete="off"
      sx={{
        px: 3,
        pt: 4,
        pb: 5,
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        width: 500,
        maxWidth: 1,
        '& .MuiTextField-root': { my: 1, width: 1 }
      }}
    >
      <Typography variant="h5" sx={{ mb: 3 }}>
        Reset Your Password
      </Typography>
      <RedxPasswordField
        fullWidth
        required
        label="New Password"
        name="newPassword"
        control={control}
        rules={{
          required: 'New password required',
          validate: () => {
            trigger('confirmPassword')
            return true
          },
          minLength: {
            value: 6,
            message: 'Minimum 6 characters required'
          }
        }}
      />
      <RedxPasswordField
        fullWidth
        required
        label="Confirm Password"
        name="confirmPassword"
        type="password"
        control={control}
        rules={{
          required: 'Confirm password required',
          validate: (value) => {
            if (value === getValues()['newPassword']) {
              return true
            } else {
              return 'Passwords do not match'
            }
          }
        }}
      />

      <Button onClick={handleSubmit(onSubmitForm)} variant="contained" sx={{ mt: 1 }} disableElevation fullWidth>
        Reset Password
      </Button>
      <Button fullWidth sx={{ mt: 1 }} onClick={() => navigate('/login')}>
        Return to Login
      </Button>
    </Paper>
  )
}
