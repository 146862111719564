import client from './apiClient'
import pdfClient from './pdfClient'
import useQueryGenerator from '../Hooks/useQueryGenerator'

const processPDF = (data) => {
  const file = new Blob([data], { type: 'application/pdf' })
  const fileURL = URL.createObjectURL(file)
  window.open(fileURL)
}

export const registryByCompany = (data) => {
  const queryString = useQueryGenerator(data)
  return client().get(`registry/company?${queryString}`)
}

export const registryByIndividual = (data) => {
  const queryString = useQueryGenerator(data)
  return client().get(`registry/individual?${queryString}`)
}

export const registryPdf = async (data) => {
  const response = await pdfClient('registry/pdf', 'POST', data)
  processPDF(response)
}

export const updateRegistry = async (data) => {
  return client().patch('registry', data)
}
