import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Paper, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { validateEmail } from '../Hooks/useFormValidation'
import { RedxTextField, RedxPasswordField } from '../Components/RedxFormFields'
import TermsAndConditions from './TermsAndConditions'
import redexLogo from '../Assets/Images/redx-logo.png'
import { login } from '../Services/auth'
import { APP_KEY } from '../consts'
import { useState } from 'react'

export default function Login() {
  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const navigate = useNavigate()
  const handleLogin = async (values, e) => {
    e.preventDefault()
    const obj = JSON.parse(localStorage.getItem(APP_KEY))
    const { pathname = '/' } = obj || {}

    try {
      const {
        data: { data }
      } = await login(values)
      localStorage.setItem(APP_KEY, JSON.stringify(data))
      navigate(pathname)
    } catch (error) {
      console.log('Login failed', error)
    }
  }

  const [openTerms, setOpenTerms] = useState(false)

  return (
    <Paper
      component={'form'}
      sx={{
        px: 3,
        pt: 4,
        pb: 5,
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        width: 500,
        maxWidth: 1,
        '& .MuiTextField-root, & .MuiFormControl-root': { my: 1, width: 1 }
      }}
    >
      <img style={{ width: 300 }} src={redexLogo} alt="Redex Logo" />
      <Typography sx={{ pt: 3, pb: 1 }}>Sign in to your account to continue</Typography>

      <RedxTextField
        required
        type="email"
        label="UserID (Email Address)"
        name="email"
        control={control}
        rules={{
          required: 'Email is required',
          validate: (value) => validateEmail(value) || 'Email is not valid'
        }}
      />
      <RedxPasswordField
        required
        label="Password"
        name="password"
        control={control}
        rules={{
          required: 'Password is required'
        }}
      />
      <Button type="submit" variant="contained" onClick={handleSubmit(handleLogin)} disableElevation fullWidth sx={{ mt: 1 }}>
        Sign in
      </Button>
      <Typography component="div" variant="caption" mt={1}>
        By selecting &quot;sign in&quot; you agree to our
        <Button
          size="small"
          sx={{ ml: 0.3, p: 0, textTransform: 'capitalize', minWidth: 0, lineHeight: 'normal', fontSize: 'inherit' }}
          onClick={() => setOpenTerms(true)}
        >
          Terms
        </Button>
      </Typography>
      <Button fullWidth sx={{ mt: 1 }} onClick={() => navigate('/forgot-password')}>
        Forgot password
      </Button>
      <Typography component="div" variant="caption" mt={1}>
        Still can’t sign in? Contact us at support@redx.ca.
      </Typography>

      <Dialog open={openTerms} onClose={() => setOpenTerms(false)} scroll="paper">
        <DialogTitle>TERMS AND CONDITIONS</DialogTitle>
        <DialogContent dividers>
          <DialogContentText tabIndex={-1}>
            <TermsAndConditions dialog />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTerms(false)}>Got it</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}
