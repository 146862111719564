import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  height: '100%'
}))

export default function AuthLayout() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', backgroundColor: (theme) => theme.palette.dark.main }}>
      <Main>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column wrap',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}
        >
          <Outlet />
        </Box>
      </Main>
    </Box>
  )
}
