import { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import RedxSearchLayout from '../Layouts/RedxSearchLayout'
import DataTable from '../Components/DataTable'
import TabsPans from '../Components/TabsPans'
import SimpleSearch from '../Components/individual-search/SimpleSearch'
import AdvancedSearch from '../Components/individual-search/AdvancedSearch'
import { individualSearch, individualSearchPdf, searchResultListPdf } from '../Services/search'

export default function IndividualSearch() {
  const [result, setResult] = useState(null)
  const handleResetResult = () => {
    setResult(null)
  }

  const [userInput, setUserInput] = useState({})
  const onSubmitForm = async (values, inputs) => {
    handleResetResult()
    setUserInput({
      ...values,
      ...inputs,
      exfromregistry: inputs.exfromregistry && 'Exclude from Registry'
    })
    const {
      data: { data }
    } = await individualSearch({
      ...values,
      ...inputs
    })
    const mutedData = data.map(({ name, company, city, province, source, incDate, ...rest }) => {
      return {
        name,
        company,
        city,
        province,
        source,
        incDate,
        ...rest
      }
    })
    setResult(mutedData)
  }

  const [selectedRows, setSelectedRows] = useState([])
  const getDataTableData = (data) => {
    setSelectedRows(data)
  }

  const handlePrintSearchResults = () => {
    searchResultListPdf(
      result.map(({ incident, ...rest }) => ({
        ...rest,
        incidentNo: incident
      })),
      Object.values(userInput).filter(Boolean).join(', ')
    )
  }

  const handleClearSelection = () => {
    setSelectedRows([])
  }

  const handleGetPDF = async () => {
    const payload = result.flatMap(({ sl, incident, name, ...rest }) => {
      if (selectedRows.some((id) => id === sl)) {
        return {
          ...rest,
          sl,
          incidentNo: incident,
          searchName: name
        }
      }
      return []
    })

    individualSearchPdf(payload)
  }

  const tabs = [
    {
      id: 1,
      label: 'Search'
    },
    {
      id: 2,
      label: 'Advanced Search'
    }
  ]

  const pans = [
    {
      id: 1,
      component: <SimpleSearch onSubmitForm={onSubmitForm} onResetForm={handleResetResult} />
    },
    {
      id: 2,
      component: <AdvancedSearch onSubmitForm={onSubmitForm} onResetForm={handleResetResult} />
    }
  ]

  return (
    <RedxSearchLayout
      form={<TabsPans tabs={tabs} pans={pans} />}
      result={
        !!result && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                Results:{' '}
                <Typography variant="p" component="span" color="red" fontSize={(theme) => theme.typography.body1}>
                  {result?.length} Record(s) found
                </Typography>
              </Typography>

              <div>
                <Button onClick={handlePrintSearchResults} variant="outlined" sx={{ mb: 1, mr: 1 }}>
                  Print Search Results
                </Button>
                <Button onClick={handleClearSelection} variant="outlined" disabled={!selectedRows?.length} sx={{ mb: 1, mr: 1 }}>
                  clear
                </Button>
                <Button onClick={handleGetPDF} variant="contained" disabled={!selectedRows?.length} sx={{ mb: 1 }}>
                  Print
                </Button>
              </div>
            </Box>
            <DataTable
              rows={result}
              callback={getDataTableData}
              selectionModel={selectedRows}
              columnVisibilityModel={{
                sanction: false,
                address: false,
                zip: false,
                licenseNo: false,
                number: false,
                firstName: false,
                lastName: false,
                incidentFull: false,
                incidentOtherInfo: false,
                parties: false
              }}
            />
          </>
        )
      }
    />
  )
}
