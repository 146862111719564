import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import PrivacyPolicy from '../Pages/PrivacyPolicy'

export default function PrivacyPolicyLink({ dialog }) {
  const navigate = useNavigate()
  const [openPrivacy, setOpenPrivacy] = useState(false)
  const handleHereBtnClick = () => {
    if (dialog) setOpenPrivacy(true)
    else navigate('/privacy-policy')
  }

  return (
    <>
      <Button variant="text" size="large" sx={{ p: 0, minWidth: 0, textTransform: 'lowercase' }} onClick={handleHereBtnClick}>
        here
      </Button>
      <Dialog open={openPrivacy} onClose={() => setOpenPrivacy(false)} scroll="paper">
        <DialogTitle>PRIVACY POLICY</DialogTitle>
        <DialogContent dividers>
          <DialogContentText tabIndex={-1}>
            <PrivacyPolicy dialog />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPrivacy(false)}>Got it</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
