import client from './apiClient'
import pdfClient from './pdfClient'
import useQueryGenerator from '../Hooks/useQueryGenerator'

const processPDF = (data) => {
  const file = new Blob([data], { type: 'application/pdf' })
  const fileURL = URL.createObjectURL(file)
  window.open(fileURL)
}

export const individualSearch = (data) => {
  const queryString = useQueryGenerator(data)
  return client().get(`search/individual?${queryString}`)
}

export const individualSearchPdf = async (data) => {
  const response = await pdfClient('search/individual/pdf', 'POST', data)
  processPDF(response)
}

export const companySearch = (data) => {
  const queryString = useQueryGenerator(data)
  return client().get(`search/company?${queryString}`)
}

export const companySearchPdf = async (data) => {
  const response = await pdfClient('search/company/pdf', 'POST', data)
  processPDF(response)
}

export const batchSearch = (file) => {
  return client().post('search/batch', file, {
    headers: { 'Content-Type': undefined }
  })
}

export const batchSearchPdf = async (data) => {
  const response = await pdfClient('search/batch/pdf', 'POST', data)
  processPDF(response)
}

export const searchResultListPdf = async (data, searchName) => {
  const response = await pdfClient(`search/print/pdf?searchName=${searchName}`, 'POST', data, false)
  processPDF(response)
}
