import { useState } from 'react'
import {
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  FormControl,
  FormGroup,
  FormControlLabel,
  InputAdornment
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { validateOnlyNumericValue } from '../../Hooks/useFormValidation'
import { RedxTextField } from '../RedxFormFields'
import RedxForm from '../RedxForm'
import provinces from '../../Assets/provinces'

export default function AdvancedSearch({ onSubmitForm, onResetForm }) {
  const { handleSubmit, control, reset, setValue } = useForm({
    mode: 'all',
    defaultValues: {
      lastname: '',
      firstname: '',
      preyear: ''
    }
  })

  const defaultValues = {
    companyname: '',
    licenseno: '',
    city: '',
    province: '',
    businessno: '',
    exfromregistry: false
  }
  const [inputs, setInputs] = useState(defaultValues)
  const handleChange = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value })
  }
  const handleCheck = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.checked })
  }

  const handleFormReset = () => {
    reset()
    setInputs(defaultValues)
    onResetForm()
  }

  return (
    <RedxForm>
      <RedxTextField
        required
        label="Last Name"
        name="lastname"
        control={control}
        rules={{
          required: 'Last name required'
        }}
      />
      <RedxTextField label="First Name" name="firstname" control={control} />
      <TextField label="License or Registration Number" name="licenseno" value={inputs['licenseno']} onChange={handleChange} />
      <TextField label="Company Name" name="companyname" value={inputs['companyname']} onChange={handleChange} />
      <TextField label="City" name="city" value={inputs['city']} onChange={handleChange} />

      <FormControl>
        <InputLabel id="province-select-label">Province</InputLabel>
        <Select
          labelId="province-select-label"
          id="province-select"
          value={inputs['province']}
          label="Province"
          name="province"
          onChange={handleChange}
        >
          {provinces.map(({ name, value }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField label="Business Number" name="businessno" value={inputs['businessno']} onChange={handleChange} />

      <RedxTextField
        label="Limit search to the previous"
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">Years</InputAdornment>
        }}
        inputProps={{ min: 0 }}
        name="preyear"
        control={control}
        rules={{
          validate: (value) => {
            if (!value) true
            else if (value <= 0) setValue('preyear', '')
            else validateOnlyNumericValue(value) || 'Only positive integer is acceptable'
          }
        }}
      />
      <FormGroup row>
        <FormControlLabel
          sx={{ mx: 1 }}
          control={<Checkbox checked={inputs['exfromregistry']} name="exfromregistry" onChange={handleCheck} />}
          label="Exclude from Registry?"
          labelPlacement="start"
        />
      </FormGroup>
      <Button type="submit" color="secondary" onClick={handleSubmit((values) => onSubmitForm(values, inputs))} variant="contained">
        Search
      </Button>
      <Button onClick={handleFormReset} variant="outlined">
        Clear
      </Button>
    </RedxForm>
  )
}
