import { Grid, Paper } from '@mui/material'

export default function RedxSearchLayout({ form, result }) {
  return (
    <Grid container columnSpacing={1} rowSpacing={2} flex={1}>
      <Grid item xs={12} sm={12} md={4}>
        <Paper variant="outlined" square sx={{ p: 3, height: 1 }}>
          {form}
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <Paper variant="outlined" square sx={{ p: 3, height: 1 }}>
          {result}
        </Paper>
      </Grid>
    </Grid>
  )
}
