import { useEffect, useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { GlobalContext } from '../Context/GlobalContextProvider'
import { termsAndConditions } from '../Assets/PagesData/terms-and-conditions'

export default function TermsAndConditions({ dialog }) {
  const { setTitle } = useContext(GlobalContext)
  useEffect(() => {
    setTitle('Terms & Conditions')
    return () => setTitle('Redx')
  }, [])

  const tac = termsAndConditions(dialog)

  return (
    <Box>
      {tac.map(({ variant, component, color, text }, i) =>
        variant !== 'el' ? (
          <Typography key={i} mb={1.5} color={color} variant={variant} component={component} dangerouslySetInnerHTML={{ __html: text }} />
        ) : (
          text
        )
      )}
    </Box>
  )
}
