/* eslint-disable no-use-before-define */
import axios from 'axios'
import { toast } from 'react-toastify'
import useAuth from '../Hooks/useAuth'
import { APP_KEY, API_URL, TOAST_MSG_OPTIONS } from '../consts'
import { loadProgressBar } from 'axios-progress-bar'

// const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

const handleLogOut = async () => {
  localStorage.setItem(APP_KEY, JSON.stringify({}))
  location.replace('/login')
}

const client = () => {
  const accessToken = useAuth()

  const options = {
    baseURL: API_URL
  }

  options.headers = {
    Accept: 'application/json'
    // 'x-user-timezone': timeZone
  }

  if (accessToken) {
    options.headers = {
      ...options.headers,
      Authorization: 'Bearer ' + accessToken
    }
  }

  const axiosInstance = axios.create(options)

  axiosInstance.interceptors.request.use(handleRequestSuccess, handleRequestError)

  axiosInstance.interceptors.response.use(handleResponseSuccess, handleResponseError)

  function handleRequestSuccess(request) {
    if (!accessToken && !request.noAuthRequired) {
      const CancelToken = axios.CancelToken
      return {
        ...request,
        cancelToken: new CancelToken((cancel) => cancel('Cancelled unauthorized request'))
      }
    }
    return request
  }

  function handleRequestError(error) {
    return Promise.reject(error)
  }

  function handleResponseSuccess(response) {
    const {
      config: { method }
    } = response

    if (method === 'post' || method === 'put' || method === 'patch') {
      const { statusCode, message } = response.data
      toast.success(`${statusCode}- ${message}`, TOAST_MSG_OPTIONS)
    }

    return response
  }

  function handleResponseError(error) {
    if (axios.isCancel(error)) return Promise.reject(error)

    const { statusCode, message } = error.response.data.error
    toast.error(`${statusCode}- ${message}`, TOAST_MSG_OPTIONS)

    if (statusCode === 401) {
      handleLogOut()
    }

    return Promise.reject(error)
  }
  loadProgressBar('', axiosInstance)

  return axiosInstance
}

export default client
