import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Link as RouterLink, useResolvedPath, useMatch } from 'react-router-dom'

export default function ListItemLink({ icon, primary, to, state, ...others }) {
  let resolved = useResolvedPath(to)
  let match = useMatch({ path: resolved.pathname, end: true })

  return (
    <ListItemButton
      sx={{
        opacity: 0.8,
        '&.Mui-selected': {
          backgroundColor: (theme) => theme.palette.dark.light,
          color: (theme) => theme.palette.common.black,
          opacity: 1,
          position: 'relative',
          '& .MuiTypography-root': { fontWeight: 500 },
          '&::before': {
            position: 'absolute',
            content: '""',
            left: 0,
            top: 0,
            bottom: 0,
            width: 3,
            backgroundColor: (theme) => theme.palette.secondary.main
          }
        },
        '&.MuiListItemButton-root': {
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            backgroundColor: (theme) => theme.palette.dark.light,
            color: (theme) => theme.palette.common.black,
            opacity: 1,
            fontWeight: 500
          }
        }
      }}
      {...others}
      component={RouterLink}
      to={to}
      selected={!!match}
      state={state}
    >
      {icon ? (
        <ListItemIcon
          sx={{
            minWidth: '38px',
            color: (theme) => theme.palette.dark.dark,
            opacity: 0.5
          }}
        >
          {icon}
        </ListItemIcon>
      ) : null}
      <ListItemText primary={primary} />
    </ListItemButton>
  )
}
