export const APP_KEY = 'REDX'
export const API_DOMAIN = 'https://app.redx.ca'
export const API_URL = `${API_DOMAIN}/api`
export const RECAPTCHA_SITE_KEY = '6LfwZ5AfAAAAAD3p7s2YwzLYGzviauFg9EN_7q7A'

export const TOAST_MSG_OPTIONS = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
}
