import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import TermsAndConditions from '../Pages/TermsAndConditions'

export default function TermsAndConditionsLink({ dialog }) {
  const navigate = useNavigate()
  const [openTerms, setOpenTerms] = useState(false)
  const handleTaCBtnClick = () => {
    if (dialog) setOpenTerms(true)
    else navigate('/terms-and-conditions')
  }

  return (
    <>
      <Button variant="text" size="large" sx={{ p: 0, minWidth: 0, textTransform: 'none' }} onClick={handleTaCBtnClick}>
        Terms &amp; Conditions
      </Button>
      <Dialog open={openTerms} onClose={() => setOpenTerms(false)} scroll="paper">
        <DialogTitle>TERMS AND CONDITIONS</DialogTitle>
        <DialogContent dividers>
          <DialogContentText tabIndex={-1}>
            <TermsAndConditions dialog />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTerms(false)}>Got it</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
