export default [
  {
    name: 'Alberta',
    value: 'AB'
  },
  {
    name: 'British Columnbia',
    value: 'BC'
  },
  {
    name: 'Manitoba',
    value: 'MB'
  },
  {
    name: 'New Brunswick',
    value: 'NB'
  },
  {
    name: 'Newfoundland',
    value: 'NL'
  },
  {
    name: 'Nova Scotia',
    value: 'NS'
  },
  {
    name: 'Nunavut',
    value: 'NT'
  },
  {
    name: 'Northwest Territories',
    value: 'NW'
  },
  {
    name: 'Ontario',
    value: 'ON'
  },
  {
    name: 'Prince Edward Island',
    value: 'PE'
  },
  {
    name: 'Quebec',
    value: 'QC'
  },
  {
    name: 'Saskatchewan',
    value: 'SK'
  },
  {
    name: 'Yukon',
    value: 'YK'
  }
]
