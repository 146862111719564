import { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { Search as SearchIcon, AppRegistration, Key as KeyIcon, Article as ArticleIcon } from '@mui/icons-material'
import CogsIcon from '../Components/svg-icons/CogsIcon'
import PuzzleIcon from '../Components/svg-icons/PuzzleIcon'
import SearchPlusIcon from '../Components/svg-icons/SearchPlusIcon'
import DatabaseIcon from '../Components/svg-icons/DatabaseIcon'
import { GlobalContext } from '../Context/GlobalContextProvider'
import useToken from '../Hooks/useToken'
import LinkCard from '../Components/home/LinkCard'
import SectionCard from '../Components/home/SectionCard'

export default function Home() {
  const { t } = useTranslation()
  const token = useToken()

  const cards = [
    {
      icon: <SearchIcon sx={{ fontSize: 40 }} />,
      text: t('home.linkTxt1'),
      color: '#ff6600',
      buttonLink: '/individual-search'
    },
    {
      icon: <AppRegistration sx={{ fontSize: 40 }} />,
      text: t('home.linkTxt2'),
      color: '#6699cc',
      buttonLink: '/registry-by-individual'
    },

    {
      icon: <ArticleIcon sx={{ fontSize: 40 }} />,
      text: t('home.linkTxt4'),
      color: '#ffcc66',
      buttonLink: '/contact-us',
      state: {
        title: 'Contact us',
        message:
          'Hello,\nI am looking forward to being able to directly upload my non-public submissions in the near future. In the meantime can you please forward  instructions for me to provide my non-public submissions.',
        persistantMessage: true,
        additionalMessage: 'Please add any additional details to your message here'
      }
    },
    {
      icon: <KeyIcon sx={{ fontSize: 40 }} />,
      text: t('home.linkTxt3'),
      color: '#99cc99',
      buttonLink: '/settings'
    }
  ]

  const { setTitle } = useContext(GlobalContext)
  useEffect(() => {
    token && setTitle(`Welcome ${token.name}`)
    return () => setTitle('Redx')
  }, [])

  const mdDown = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const smDown = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container spacing={2} justifyContent="center" flexWrap="wrap">
        {cards.map((card, i) => (
          <Grid xs={12} sm={6} md={3} item key={i}>
            <LinkCard {...card} />
          </Grid>
        ))}
      </Grid>

      <Grid item xs={12} container>
        <SectionCard height={'auto'} sx={{ py: 3, px: 1.5 }}>
          <Typography variant="h5" component="div" fontWeight={600} textAlign="center" pb={4}>
            Discover more of REDX™
          </Typography>
          <Grid
            container
            rowSpacing={4}
            columnSpacing={5}
            sx={{
              '& .MuiGrid-root.MuiGrid-item': {
                position: 'relative',
                [`&:not(${smDown ? ':nth-child(1n)' : mdDown ? ':nth-of-type(even)' : ':last-child'}):not(:last-child)::after`]: {
                  position: 'absolute',
                  content: '""',
                  width: 2,
                  height: 'calc(100% - 32px)',
                  top: 32,
                  right: -20,
                  backgroundColor: (theme) => theme.palette.divider
                }
              }
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{
                display: 'flex',
                columnGap: 2
              }}
            >
              <CogsIcon sx={{ fontSize: 38 }} />
              <Box>
                <Typography variant="h6" component="div" pb={2}>
                  Ongoing Continuous Risk Monitoring
                </Typography>
                <Typography variant="p" component="div">
                  The REDX™ Registry Service provides alerts and notifications if and when new or updated information becomes available on
                  your customized selection of individuals and companies
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', columnGap: 2 }}>
              <PuzzleIcon sx={{ fontSize: 38 }} />
              <Box>
                <Typography variant="h6" component="div" pb={2}>
                  Workflow Integration
                </Typography>
                <Typography variant="p" component="div">
                  The REDX™ Registry Service provides for API integration into internal systems to facilitate efficient workflows
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', columnGap: 2 }}>
              <SearchPlusIcon sx={{ fontSize: 38 }} />
              <Box>
                <Typography variant="h6" component="div" pb={2}>
                  Audit Trail
                </Typography>
                <Typography variant="p" component="div">
                  The REDX™ Service provides a referenceable audit trail of your diligence process
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex', columnGap: 2 }}>
              <DatabaseIcon sx={{ fontSize: 38 }} />
              <Box>
                <Typography variant="h6" component="div" pb={2}>
                  Collective Risk Intelligence
                </Typography>
                <Typography variant="p" component="div">
                  The REDX™ Service provides multi-jurisdictional and multi-professional coverage with over two decades of critical risk
                  management data
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </SectionCard>
      </Grid>
    </Grid>
  )
}
