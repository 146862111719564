export const faqs = (slots) => {
  return [
    {
      question: 'What is REDX?',
      answers: [
        {
          chunk1:
            'Established in 2003, The Real Estate Data Exchange™ (REDX) service has been an essential partner for almost two decades in providing risk management solutions to the mortgage and financial service industries in Canada. REDX provides a solution with Canada wide coverage and that enables subscribers to have a comprehensive view of the professionals and companies with whom they do business.'
        },
        {
          chunk1:
            'The REDX Service provides critical diligence information including disciplinary, enforcement and certain legal actions taken by regulatory bodies and self-regulating organizations that oversee professionals in the mortgage, real estate, legal, insurance, banking, securities, and related financial services industries. This information is further complemented by key data contributions from our subscribers who oversee a large share of mortgage and real estate transactions in Canada.'
        }
      ]
    },
    {
      question: 'What is the REDX Public Service?',
      answers: [
        {
          chunk1:
            'The REDX Public Service provides online remote access to data and information referencing public disciplinary, enforcement and legal actions related to professionals and companies in the mortgage and financial services industries. A single search can return regulatory information about the status of professional or corporate service providers. The REDX Public Service affords subscribers the option for ongoing, continuous risk monitoring of professionals and companies that a subscriber chooses to follow in their registries.'
        }
      ]
    },
    {
      question: 'What is the REDX Non-Public Service?',
      answers: [
        {
          chunk1:
            "The REDX Non-Public Service provides access to information contributed by REDX subscribers regarding incidents of alleged fraud, material misrepresentation and serious misconduct by professionals and companies in the mortgage and mortgage-related industries. Non-Public subscribers are subject to eligibility requirements and include national and regional lenders, mortgage and title insurers, and mortgage and financial industry service providers. The outcome of a subscriber's internal compliance reviews determines if information is submitted to the Non- Public Service."
        }
      ]
    },
    {
      question: 'What is the REDX Service used for?',
      answers: [
        {
          chunk1:
            'The REDX Service is used by various participants in the mortgage and financial services industries, including but not limited to mortgage lenders, investors, and insurers, as part of their due diligence on new or existing business relationships. Brokers, loan officers, lawyers, appraisers, are some of the corporations and professionals covered by the REDX Service.'
        },
        {
          chunk1:
            'For example, a lender may search a prospective third-party originator (company and key individuals) through the REDX Service as part of its due diligence process before commencing a business relationship.'
        }
      ]
    },
    {
      question: 'What is ‘Ongoing, Continuous Risk Monitoring’?',
      answers: [
        {
          chunk1:
            'Ongoing, continuous risk monitoring services allow for subscribers to get new and updated information on potential or current business partners that may be relevant to their risk and compliance assessment. The REDX ongoing, continuous risk monitoring service allows a subscriber to follow specific individuals or companies in their custom registries and as new or updated information with regards to these individuals and companies becomes available, an alert and notification is sent to the subscriber.'
        }
      ]
    },
    {
      question: 'What is a Registry?',
      answers: [
        {
          chunk1:
            'A registry is a list of professionals and companies that a subscriber chooses to follow as part of their ongoing and continuous risk monitoring of current and potential business partners. A subscriber’s registry is customized and specific to that subscriber.'
        }
      ]
    },
    {
      question: 'How can I change my Ongoing Continuous Risk Monitoring Registry settings?',
      answers: [
        {
          chunk1:
            'A subscriber’s registry settings can only be changed by users who are appropriately authorized to make such changes. To change your registry please contact us',
          chunk2: slots['slot5']
        }
      ]
    },
    {
      question: 'Do I need to integrate the REDX Service with my internal systems?',
      answers: [
        {
          chunk1: 'No system integration is required. The REDX Service is web-based and easy to use.'
        }
      ]
    },
    {
      question: 'Can I integrate the REDX Service with my internal systems to improve my workflow?',
      answers: [
        {
          chunk1: 'REDX exposes a number of RESTful API you can use to interface our system programmatically. Please',
          chunk2: slots['slot1'],
          chunk3: 'to request an API Key and API documentation.'
        }
      ]
    },
    {
      question: 'What kind of public information does the REDX Service reference?',
      answers: [
        {
          chunk1:
            'The REDX Public Service Reports contain references to indexed information from government regulatory agencies, self-regulated organizations (SRO’s), and public court sources related to professionals and companies in the mortgage, real estate, appraisal, financial services, and securities industries in Canada. This information primarily references records of disciplinary, enforcement and certain legal actions.'
        }
      ]
    },
    {
      question: 'What is the scope of the REDX Public Service?',
      answers: [
        {
          chunk1:
            'REDX Public Service incorporates regulatory sources across Canada and across multiple financial industry sectors. As the mobility of industry professionals increases, our national cross-jurisdictional solution facilitates subscriber due-diligence.'
        }
      ]
    },
    {
      question: 'Does REDX identify the sources of its information?',
      answers: [
        {
          chunk1:
            'The REDX Service identifies the source of the public data in its Public Service Reports referencing process. The REDX Service does not provide the source of the information in the Non-Public Reports. The REDX Non-Public Service is a blind exchange of information among qualified REDX subscribers.'
        }
      ]
    },
    {
      question: 'What kind of information do REDX Non-Public Service Reports contain?',
      answers: [
        {
          chunk1:
            'REDX Non-Public Reports are provided by qualified subscribers and include incidents of alleged fraud and material misrepresentation. Non-Public Reports provide a narrative describing the incident, identifying the originator of record (the individual professional and/or company that took the application from the consumer), and identifying other professional parties, as appropriate, who were involved with the transaction. The REDX Non-Public Service does not contain general business information, employment histories, etc.'
        }
      ]
    },
    {
      question: 'How can I correct the information in a REDX Public Report?',
      answers: [
        {
          chunk1:
            'The REDX database references public information from regulatory agencies and other public sources. The REDX Service does not create any public record or publicly available information. If there is an error in a REDX Public Service Report, it is most often because the original source has published inaccurate information. The REDX Service can provide you with information on the regulatory source; however, we cannot correct inaccurate information. You must contact the source and follow up with them to make sure changes are made.'
        }
      ]
    },
    {
      question: 'How can I correct the information in a REDX Non-Public Report?',
      answers: [
        {
          chunk1:
            'Since we do not create the non-public information, we cannot correct the information that is reported; however, the REDX Service has a resolution process that allows you to respond to information contained in a REDX Non-Public Report. We will send your response to the company (submitting subscriber) that submitted the report to us. After reviewing your response, the submitting subscriber can remove, modify or make no change to the REDX Non-Public Report. We will then add your response to the REDX Non-Public Report if requested to do so. We will also send a copy of the updated REDX Non-Public Report (or a deletion notice) to each subscriber who received a copy of the report in the last two years.'
        }
      ]
    },
    {
      question: 'How long does the information in REDX Reports remain available?',
      answers: [
        {
          chunk1:
            'Public information in the REDX Service remains in the database in perpetuity. Non-public information is available through the REDX Service for up to 10 years after the date of the reported incident; it is then archived for auditing purposes.'
        }
      ]
    },
    {
      question: 'Can my company subscribe to the REDX database services?',
      answers: [
        {
          chunk1:
            'REDX Services are available to businesses and regulatory bodies in the financial services industries operating in Canada. Please contact our',
          chunk2: slots['slot3'],
          chunk3: ' for more information.'
        }
      ]
    },
    {
      question: 'How do I contact REDX?',
      answers: [
        {
          chunk1: 'For more information on how to contact us,',
          chunk2: slots['slot4']
        }
      ]
    }
  ]
}
