import { styled } from '@mui/material/styles'

const StyledForm = styled('form')(() => ({
  width: '100%',
  maxWidth: 810,
  display: 'flex',
  flexFlow: 'row wrap',
  gap: 10,
  '& .MuiTextField-root, & .MuiFormControl-root': {
    width: '100%',
    maxWidth: 400
  },
  '& .MuiFormGroup-root': {
    width: '100%'
  }
}))

export default function RedxForm({ children }) {
  return <StyledForm autoComplete="off">{children}</StyledForm>
}
