import { Link, useNavigate } from 'react-router-dom'
// import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'
import { AppBar as MuiAppBar, Toolbar, Typography, IconButton } from '@mui/material'
import {
  Menu as MenuIcon,
  Settings as SettingsIcon,
  Bungalow,
  ManageAccounts,
  LockOpen as LockOpenIcon,
  PowerSettingsNew
} from '@mui/icons-material'
import { APP_KEY } from '../consts'
import IconMenu from './IconMenu'
import { logout } from '../Services/auth'

export default function Header({ title, open, width, handler }) {
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
  })(({ theme, open }) => ({
    backgroundColor: theme.palette.dark.lighter,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    '&::after': {
      position: 'absolute',
      content: '""',
      bottom: 0,
      left: 24,
      right: 24,
      width: 'calc(100% - 48px)',
      height: '1px',
      backgroundColor: theme.palette.divider
    },
    ...(open && {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${width}px)`,
        marginLeft: `${width}px`
      },
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  }))

  // const { i18n } = useTranslation()
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng)
  // }

  const navigate = useNavigate()
  const handleLogOut = () => {
    logout()
    localStorage.setItem(APP_KEY, JSON.stringify({}))
    navigate('/login')
  }

  return (
    <AppBar position="fixed" open={open} color="common" sx={{ boxShadow: 0 }}>
      <Toolbar>
        <IconButton color="inherit" onClick={handler} edge="start" sx={{ mr: 2, ...(open && { display: 'none' }) }}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          {title && title.toUpperCase()}
        </Typography>
        <IconButton component={Link} to="/" size="small" sx={{ mr: 1, display: { sm: 'inline-flex', md: 'none' } }}>
          <Bungalow sx={{ fontSize: 24, color: (theme) => theme.palette.grey[500] }} />
        </IconButton>
        <IconMenu
          menuId="redx-settings-menu"
          icon={<SettingsIcon sx={{ fontSize: 24, color: (theme) => theme.palette.grey[500] }} />}
          menus={[
            // {
            //   groupLabel: 'Languages',
            //   items: [
            //     {
            //       icon: <Avatar sx={{ width: 20, height: 20, fontSize: 12 }}>en</Avatar>,
            //       name: 'English',
            //       handler: () => changeLanguage('en')
            //     },
            //     {
            //       icon: <Avatar sx={{ width: 20, height: 20, fontSize: 12 }}>fr</Avatar>,
            //       name: 'French',
            //       handler: () => changeLanguage('fr')
            //     }
            //   ]
            // },
            {
              groupLabel: 'General Settings',
              items: [
                {
                  icon: <ManageAccounts />,
                  name: 'Profile Settings',
                  handler: () => navigate('/settings', { state: { title: 'Settings', selected: 0 } })
                },
                {
                  icon: <LockOpenIcon />,
                  name: 'Change Password',
                  handler: () => navigate('/settings', { state: { title: 'Settings', selected: 1 } })
                }
              ]
            },
            {
              items: [
                {
                  icon: <PowerSettingsNew />,
                  name: 'Log out',
                  handler: handleLogOut
                }
              ]
            }
          ]}
        />
      </Toolbar>
    </AppBar>
  )
}
