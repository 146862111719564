import { Card, CardHeader, CardContent, Divider } from '@mui/material'

export default function SectionCard({ title, subTitle, children, height = 350, ...rest }) {
  return (
    <Card variant="outlined" {...rest}>
      {title && (
        <>
          <CardHeader title={title} subheader={subTitle} />
          <Divider />
        </>
      )}
      <CardContent
        sx={{
          height: height,
          minHeight: 250,
          overflow: 'auto'
        }}
      >
        {children}
      </CardContent>
    </Card>
  )
}
