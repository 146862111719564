import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Checkbox, FormGroup, FormControlLabel } from '@mui/material'
import { RedxTextField } from '../RedxFormFields'
import RedxForm from '../RedxForm'

export default function SimpleSearch({ onSubmitForm, onResetForm }) {
  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: {
      companyname: ''
    }
  })

  const defaultValues = {
    exfromregistry: false
  }
  const [inputs, setInputs] = useState(defaultValues)
  const handleCheck = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.checked })
  }

  const handleFormReset = () => {
    reset()
    setInputs(defaultValues)
    onResetForm()
  }

  return (
    <RedxForm>
      <RedxTextField
        required
        label="Company Name"
        name="companyname"
        control={control}
        rules={{
          required: 'Company name required'
        }}
      />
      <FormGroup row>
        <FormControlLabel
          sx={{ mx: 1 }}
          control={<Checkbox checked={inputs['exfromregistry']} name="exfromregistry" onChange={handleCheck} />}
          label="Exclude from Registry?"
          labelPlacement="start"
        />
      </FormGroup>
      <Button type="submit" color="secondary" onClick={handleSubmit((values) => onSubmitForm(values, inputs))} variant="contained">
        Search
      </Button>
      <Button onClick={handleFormReset} variant="outlined">
        Clear
      </Button>
    </RedxForm>
  )
}
