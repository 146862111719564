import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import {
  Grid,
  Typography,
  Button,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Divider
} from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { faqs } from '../Assets/PagesData/faqs'

export default function FAQ() {
  const navigate = useNavigate()
  const handleContactUs = () => {
    navigate('/contact-us', {
      state: {
        title: 'Contact us',
        message: 'Hi REDX,\nI’d like to request an API key and API documentation.Can you please send instructions and key account details?'
      }
    })
  }

  const handleRiskMonitoring = () => {
    navigate('/contact-us', {
      state: {
        title: 'Contact us',
        message:
          'Hello REDX Support Team,\nI would like to adjust my ongoing risk monitoring settings by editing my Registry. Please provide the required information for me to make the necessary changes.'
      }
    })
  }

  const [expanded, setExpanded] = useState(false)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const slots = {
    slot1: (props) => (
      <Button variant="text" size="small" sx={{ py: 0 }} {...props} onClick={handleContactUs}>
        Click here
      </Button>
    ),
    slot2: <Divider sx={{ my: 1 }} />,
    slot3: (
      <Button component="a" href="mailto:support@redx.ca" variant="text" size="small" sx={{ py: 0 }}>
        Sales team
      </Button>
    ),
    slot4: (
      <Button variant="text" size="small" sx={{ py: 0 }} onClick={() => navigate('/contact-us', { state: { title: 'Contact us' } })}>
        Click here
      </Button>
    ),
    slot5: (props) => (
      <Button variant="text" size="small" sx={{ py: 0, minWidth: 0 }} {...props} onClick={handleRiskMonitoring}>
        Here
      </Button>
    )
  }

  const faqData = faqs(slots)

  const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  }))

  const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1)
    }
  }))

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
  }))

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography variant="p" component="p">
          We have compiled a list of frequently asked questions regarding the REDX Service. Hopefully your questions will be addressed here.
          If not, please
          {slots['slot4']}
          for additional information.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {faqData.map(({ question, answers }, i) => (
          <Accordion key={i} expanded={expanded === i} onChange={handleChange(i)}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography variant="h6" component="div">
                {question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {answers.map((ans, i) => (
                <Typography key={i} gutterBottom variant="p" component="div">
                  {Object.entries(ans).map(([key, value]) => (
                    <span key={key}>{typeof value === 'function' ? value() : value}</span>
                  ))}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  )
}
