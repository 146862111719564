import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Paper, Typography, Button } from '@mui/material'
import { validateEmail } from '../Hooks/useFormValidation'
import { RedxTextField } from '../Components/RedxFormFields'
import { forgotPassword } from '../Services/auth'

export default function ForgotPassword() {
  const navigate = useNavigate()

  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: {
      email: ''
    }
  })

  const [mailSent, setMailSent] = useState(false)
  const handleSubmitEmail = async (values, e) => {
    e.preventDefault()

    const { protocol, hostname } = window.location
    const payload = {
      ...values,
      appUrl: `${protocol}//${hostname}/reset-password`
    }

    try {
      await forgotPassword(payload)
      setMailSent(true)
    } catch (error) {
      console.log('forgot Password failed', error)
    }
  }

  return (
    <Paper
      component={'form'}
      autoComplete="off"
      sx={{
        px: 3,
        pt: 4,
        pb: 5,
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        width: 500,
        maxWidth: 1,
        '& .MuiTextField-root': { my: 1, width: 1 }
      }}
    >
      {mailSent ? (
        <>
          <Typography variant="h4" color="primary" mb={2}>
            Reset Password
          </Typography>
          <Typography pb={2} fontSize={14} textAlign="center">
            An mail has been sent to help you reset the password. If you cannot find it, please check your spam/junk inbox. If you are still
            having troubles, please contact support.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h5" sx={{ mb: 1 }}>
            Forgot Password?
          </Typography>
          <Typography sx={{ pb: 2 }}>Reset password in two quick steps</Typography>

          <RedxTextField
            required
            type="email"
            label="Email Address"
            name="email"
            control={control}
            rules={{
              required: 'Email required',
              validate: (value) => validateEmail(value) || 'Email is not valid'
            }}
          />
          <Button type="submit" variant="contained" onClick={handleSubmit(handleSubmitEmail)} disableElevation fullWidth>
            Submit
          </Button>
        </>
      )}

      <Button fullWidth sx={{ mt: 1 }} onClick={() => navigate('/login')}>
        Return to Login
      </Button>
    </Paper>
  )
}
