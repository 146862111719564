import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Box, InputAdornment, IconButton, Alert } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { RedxTextField } from '../Components/RedxFormFields'
import { validateEmail } from '../Hooks/useFormValidation'
import { updateUser } from '../Services/user'

const ProfileField = (props) => {
  const [disabled, setDisabled] = useState(true)
  return (
    <RedxTextField
      {...props}
      InputProps={{
        disabled,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setDisabled((prevState) => !prevState)} edge="end">
              <EditIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

export default function ProfileSettings({ userInfo, callback }) {
  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      address: ''
    }
  })

  const [updateStatus, setUpdateStatus] = useState({ show: false })
  const handleFormReset = (payload) => {
    const { firstName = '', lastName = '', email = '', phoneNumber = '', address = '' } = payload
    reset({
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      phoneNumber: phoneNumber || '',
      address: address || ''
    })
  }

  useEffect(() => {
    handleFormReset(userInfo)
  }, [userInfo])

  const onSubmitForm = async (values) => {
    try {
      const {
        data: { data }
      } = await updateUser({ id: userInfo.contactId, data: values })
      // handleFormReset(data)
      setUpdateStatus({
        show: true,
        severity: 'success',
        message: 'These changes will be reflected on your next log in.'
      })
      callback(data)
    } catch (error) {
      const { message } = error.response.data.error
      setUpdateStatus({
        show: true,
        severity: 'error',
        message
      })
    }
  }

  const navigate = useNavigate()
  const handleCancel = () => {
    navigate(-1)
  }

  return (
    <Box
      component="form"
      sx={{
        maxWidth: 500,
        '& .MuiTextField-root': { my: 1, width: 1 }
      }}
      noValidate
      autoComplete="off"
    >
      <ProfileField required label="First Name" name="firstName" control={control} rules={{ required: 'First Name is required' }} />
      <ProfileField required label="Last Name" name="lastName" control={control} rules={{ required: 'Last Name is required' }} />
      <ProfileField
        required
        type="email"
        label="Email Address"
        name="email"
        control={control}
        rules={{
          required: 'Email is required',
          validate: (value) => validateEmail(value) || 'Email is not valid'
        }}
      />
      {/* <ProfileField label="Phone Number" name="phoneNumber" control={control} />
      <ProfileField label="Address" name="address" control={control} /> */}

      <Button type="submit" color="secondary" onClick={handleSubmit(onSubmitForm)} variant="contained" sx={{ mt: 1 }}>
        Save
      </Button>
      <Button onClick={handleCancel} variant="outlined" sx={{ mt: 1, ml: 1 }}>
        Cancel
      </Button>

      {!!updateStatus.show && (
        <Alert sx={{ mt: 4 }} severity={updateStatus.severity} onClose={() => setUpdateStatus({ show: false })}>
          {updateStatus.message}
        </Alert>
      )}
    </Box>
  )
}
