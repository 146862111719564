import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Paper } from '@mui/material'
import TabsPans from '../Components/TabsPans'
import ProfileSettings from './ProfileSettings'
import ChangePassword from './ChangePassword'
import useToken from '../Hooks/useToken'
import { getUser } from '../Services/user'

export default function GeneralSettings() {
  const { state = {} } = useLocation()
  const { selected = 0 } = state || {}

  const { contactId } = useToken()
  const [userInfo, setUserInfo] = useState({})
  useEffect(() => {
    const getUserDetails = async () => {
      const { data: { data = {} } = {} } = await getUser(contactId)
      setUserInfo(data)
    }
    getUserDetails()
  }, [])

  const tabs = [
    {
      id: 1,
      label: 'Profile Settings'
    },
    {
      id: 2,
      label: 'Change Password'
    }
  ]

  const pans = [
    {
      id: 1,
      component: <ProfileSettings userInfo={userInfo} callback={setUserInfo} />
    },
    {
      id: 2,
      component: <ChangePassword />
    }
  ]

  return (
    <Paper variant="outlined" square sx={{ p: 3, flex: 1 }}>
      <TabsPans tabs={tabs} pans={pans} selected={selected || 0} />
    </Paper>
  )
}
