import { APP_KEY } from '../consts'

export default function useAuth() {
  let userInfo
  try {
    userInfo = JSON.parse(localStorage.getItem(APP_KEY))
  } catch (error) {
    return null
  }

  if (!userInfo) return

  const { token } = userInfo

  return token
}
