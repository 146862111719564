import { useState, useContext, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'
import { GlobalContext } from '../Context/GlobalContextProvider'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

const drawerWidth = 258

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(1),
  backgroundColor: theme.palette.dark.lighter,
  minHeight: 'calc(100vh - 48px)',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: `${drawerWidth}px`
    }
  })
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}))

export default function DefaultLayout() {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const [open, setOpen] = useState(true)
  useEffect(() => {
    setOpen(mdUp)
  }, [mdUp])

  const handleDrawerToggle = () => {
    setOpen((state) => !state)
  }

  const { state } = useLocation()
  const { title, setTitle } = useContext(GlobalContext)
  useEffect(() => {
    state?.title && setTitle(state.title)
  }, [state])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Header title={title} open={open} width={drawerWidth} handler={handleDrawerToggle} />

      <Sidebar open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)} width={drawerWidth} handler={handleDrawerToggle} />

      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>

      <Footer open={open} width={drawerWidth} />
    </Box>
  )
}
