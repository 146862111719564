import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
import { Box, Button, Typography } from '@mui/material'
import RedxSearchLayout from '../Layouts/RedxSearchLayout'
import RedxForm from '../Components/RedxForm'
import { RedxTextField, RedxDateRangePicker } from '../Components/RedxFormFields'
import DataTable from '../Components/DataTable'
import { registryByCompany, registryPdf } from '../Services/registry'

export default function RegistryByCompany() {
  const { handleSubmit, control, reset } = useForm({
    mode: 'all',
    defaultValues: {
      company: ''
    }
  })

  const [dateRange, setDateRange] = useState([null, null])

  const [result, setResult] = useState(null)
  const onSubmitForm = async (values) => {
    if (!dateRange[0] || !dateRange[1]) return
    setResult(null)
    const payload = {
      ...values,
      fromdate: format(dateRange[0], 'yyyy-MM-dd'),
      todate: format(dateRange[1], 'yyyy-MM-dd')
    }
    const {
      data: { data }
    } = await registryByCompany(payload)

    const mutedData = data.map(({ name, licenseNo, company, date, sl }) => {
      return {
        name,
        licenseNo,
        company,
        regDate: date,
        sl
      }
    })
    setResult(mutedData)
  }

  const handleFormReset = () => {
    setDateRange([null, null])
    reset()
    setResult(null)
  }

  const [selectedRows, setSelectedRows] = useState([])
  const getDataTableData = (data) => {
    setSelectedRows(data)
  }

  const handleClearSelection = () => {
    setSelectedRows([])
  }

  const handleGetPDF = async () => {
    const payload = result.flatMap(({ sl }) => {
      if (selectedRows.some((id) => id === sl)) {
        return {
          keynum: sl
        }
      }
      return []
    })

    registryPdf(payload)
  }

  // const handleUnsubscribe = () => {
  //   const payload = result.flatMap(({ sl }) => {
  //     if (selectedRows.some((id) => id === sl)) {
  //       return {
  //         keynum: sl,
  //         inactiveDate: format(new Date(), 'yyyy-MM-dd hh:mm:ss')
  //       }
  //     }
  //     return []
  //   })

  //   updateRegistry(payload)
  // }

  const navigate = useNavigate()
  const handleContactUs = () => {
    navigate('/contact-us', {
      state: {
        title: 'Contact us',
        message:
          'Hello REDX Support Team,\nI would like to adjust my ongoing risk monitoring settings by editing my Registry. Please provide the required information for me to make the necessary changes.'
      }
    })
  }

  return (
    <RedxSearchLayout
      form={
        <RedxForm>
          <Typography component="div" variant="body2" mt={1} textAlign="left" width={1}>
            To adjust your ongoing risk monitoring registry settings please contact us
            <Button size="small" sx={{ ml: 0.5, p: 0, textTransform: 'none', minWidth: 0, lineHeight: 'normal' }} onClick={handleContactUs}>
              here
            </Button>
          </Typography>

          <RedxDateRangePicker value={dateRange} onChange={(newValue) => setDateRange(newValue)} />

          <RedxTextField label="Company Name" name="company" control={control} />

          <Box sx={{ flexBasis: '100%', height: 0 }} />
          <Button type="submit" color="secondary" onClick={handleSubmit(onSubmitForm)} variant="contained">
            Search
          </Button>
          <Button onClick={handleFormReset} variant="outlined">
            Clear
          </Button>
        </RedxForm>
      }
      result={
        !!result && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                Results:{' '}
                <Typography variant="p" component="span" color="secondary" fontSize={(theme) => theme.typography.body1}>
                  {result?.length} Record(s) found
                </Typography>
              </Typography>

              <div>
                {/* <Button onClick={handleUnsubscribe} variant="outlined" disabled={!selectedRows?.length} sx={{ mb: 1, mr: 1 }}>
                  Unsubscribe
                </Button> */}
                <Button onClick={handleClearSelection} variant="outlined" disabled={!selectedRows?.length} sx={{ mb: 1, mr: 1 }}>
                  clear
                </Button>
                <Button onClick={handleGetPDF} variant="contained" disabled={!selectedRows?.length} sx={{ mb: 1 }}>
                  Print
                </Button>
              </div>
            </Box>
            <DataTable rows={result} callback={getDataTableData} selectionModel={selectedRows} />
          </>
        )
      }
    />
  )
}
