import { useState, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button, Typography, Alert } from '@mui/material'
import { GlobalContext } from '../Context/GlobalContextProvider'
import ExcelIcon from '../Components/svg-icons/ExcelIcon'
import PDFIcon from '../Components/svg-icons/PDFIcon'
import RedxSearchLayout from '../Layouts/RedxSearchLayout'
import RedxForm from '../Components/RedxForm'
import DataTable from '../Components/DataTable'
import { RedxFileUploader } from '../Components/RedxFormFields'
import { batchSearch, batchSearchPdf, searchResultListPdf } from '../Services/search'
import { API_URL } from '../consts'

export default function BatchSearch() {
  const { setLoader } = useContext(GlobalContext)

  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: {
      file: ''
    }
  })

  const [result, setResult] = useState(null)
  const [searchStatus, setSearchStatus] = useState({ show: false })
  const onSubmitForm = async (values) => {
    setLoader(true)
    setResult([])
    const formData = new FormData()
    formData.append('file', values.file[0])

    try {
      const {
        data: {
          data: { results, errors }
        }
      } = await batchSearch(formData)
      setLoader(false)
      const mutedData = results.map(({ name, company, city, province, source, incDate, ...rest }) => {
        return {
          name,
          company,
          city,
          province,
          source,
          incDate,
          ...rest
        }
      })
      setResult(mutedData)

      if (errors?.length) {
        setSearchStatus({
          show: true,
          severity: 'error',
          message: errors
        })
      } else {
        setSearchStatus({ show: false })
      }
    } catch (error) {
      setLoader(false)
    }
  }

  const [key, setKey] = useState(0)
  const handleFormReset = () => {
    setResult(null)
    setKey((prevKey) => prevKey + 1)
    setSearchStatus({ show: false })
  }

  const [selectedRows, setSelectedRows] = useState([])
  const getDataTableData = (data) => {
    setSelectedRows(data)
  }

  const handlePrintSearchResults = () => {
    searchResultListPdf(
      result.map(({ incident, ...rest }) => ({
        ...rest,
        incidentNo: incident
      })),
      'Batch search'
    )
  }

  const handleClearSelection = () => {
    setSelectedRows([])
  }

  // const handleDownloadTemplate = () => {
  //   const a = document.createElement('a')
  //   a.href = `${API_URL}/doc/redx_batch_search_template.xls`
  //   a.setAttribute('download', 'redx_batch_search_template')
  //   a.click()
  // }

  const handleGetPDF = async () => {
    const payload = result.flatMap(({ sl, incident, name, ...rest }) => {
      if (selectedRows.some((id) => id === sl)) {
        return {
          ...rest,
          sl,
          incidentNo: incident,
          searchName: name
        }
      }
      return []
    })

    batchSearchPdf(payload)
  }

  return (
    <RedxSearchLayout
      form={
        <RedxForm>
          <RedxFileUploader
            key={key}
            required
            type="file"
            inputProps={{
              accept: 'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }}
            name="file"
            control={control}
            rules={{ required: 'File required' }}
            helperText="Only csv and excel files are accepted. All batch searches are included in Registry. "
          />

          <Button
            sx={{ alignSelf: 'flex-start', textTransform: 'capitalize', py: 0.5 }}
            size="large"
            variant="text"
            component="a"
            href={`${API_URL}/doc/redx_batch_search_template.xls`}
            download="redx_batch_search_template"
            startIcon={<ExcelIcon fontSize="inherit" />}
          >
            REDX Batch Search Template
          </Button>
          <Button
            sx={{ alignSelf: 'flex-start', textTransform: 'capitalize', py: 0.5 }}
            size="large"
            variant="text"
            component="a"
            href="REDX_Batch_Search_Instructions.pdf"
            download="REDX Batch Search Instructions"
            startIcon={<PDFIcon sx={{ color: 'red' }} fontSize="inherit" />}
          >
            REDX Batch Search Instructions
          </Button>
          <Box sx={{ flexBasis: '100%', height: 0 }} />
          <Button color="secondary" onClick={handleSubmit(onSubmitForm)} variant="contained">
            Search
          </Button>
          <Button onClick={handleFormReset} variant="outlined">
            Clear
          </Button>

          <Box sx={{ flexBasis: '100%', height: 0 }} />
          {!!searchStatus.show && (
            <Alert sx={{ mt: 4 }} severity={searchStatus.severity} onClose={() => setSearchStatus({ show: false })}>
              {searchStatus.message.map((err, i) => (
                <Typography key={i} component={'div'} variant="p" mb={1}>
                  - {err}
                </Typography>
              ))}
            </Alert>
          )}
        </RedxForm>
      }
      result={
        !!result && (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h5" component="div" sx={{ mb: 1 }}>
                Results:{' '}
                <Typography variant="p" component="span" color="secondary" fontSize={(theme) => theme.typography.body1}>
                  {result?.length} Record(s) found
                </Typography>
              </Typography>

              <div>
                <Button onClick={handlePrintSearchResults} variant="outlined" sx={{ mb: 1, mr: 1 }}>
                  Print Search Results
                </Button>
                <Button onClick={handleClearSelection} variant="outlined" disabled={!selectedRows?.length} sx={{ mb: 1, mr: 1 }}>
                  clear
                </Button>
                <Button onClick={handleGetPDF} variant="contained" disabled={!selectedRows?.length} sx={{ mb: 1 }}>
                  Print
                </Button>
              </div>
            </Box>
            <DataTable
              rows={result}
              callback={getDataTableData}
              selectionModel={selectedRows}
              columnVisibilityModel={{
                sanction: false,
                address: false,
                zip: false,
                licenseNo: false,
                number: false,
                firstName: false,
                lastName: false,
                incidentFull: false,
                incidentOtherInfo: false,
                parties: false,
                searchName: false
              }}
            />
          </>
        )
      }
    />
  )
}
