import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, Divider } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import ListItemLink from './ListItemLink'

const ChildListItemLink = styled(ListItemLink)(({ theme }) => ({
  paddingLeft: theme.spacing(7)
}))

export default function ListItemCollapsible(props) {
  const { icon, primary, subLink } = props

  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  const { pathname } = useLocation()
  useEffect(() => {
    if (!open) {
      const isActiveParent = subLink.some((link) => link.path === pathname)
      setOpen(isActiveParent)
    }
  }, [pathname])

  return (
    <>
      <ListItemButton
        sx={{
          opacity: 0.8,
          '&.Mui-selected': {
            backgroundColor: (theme) => theme.palette.dark.lighter,
            boxShadow: (theme) => theme.shadows[1]
          },
          '&.MuiListItemButton-root': {
            transition: 'all 0.1s ease-in-out',
            '&:hover': {
              backgroundColor: (theme) => theme.palette.dark.light,
              color: (theme) => theme.palette.common.black,
              opacity: 1,
              fontWeight: 500
            }
          }
        }}
        selected={open}
        onClick={handleClick}
      >
        {icon ? (
          <ListItemIcon
            sx={{
              minWidth: '38px',
              color: (theme) => theme.palette.dark.dark,
              opacity: 0.5
            }}
          >
            {icon}
          </ListItemIcon>
        ) : null}
        <ListItemText primary={primary} />
        {open ? <ExpandLess sx={{ opacity: 0.5 }} /> : <ExpandMore sx={{ opacity: 0.5 }} />}
      </ListItemButton>

      {!open && <Divider />}

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div">
          {subLink.map(({ icon, text, path, state }, i) => (
            <ChildListItemLink key={i} icon={icon} primary={text} to={path} state={state} />
          ))}
        </List>
        {open ? <Divider /> : null}
      </Collapse>
    </>
  )
}
