import { createContext, useState } from 'react'

export const GlobalContext = createContext()

export default function GlobalContextProvider({ children }) {
  const [title, setTitle] = useState('Redx')
  const [loader, setLoader] = useState(false)

  return (
    <GlobalContext.Provider
      value={{
        title,
        loader,
        setTitle,
        setLoader
      }}
    >
      {children}
    </GlobalContext.Provider>
  )
}
