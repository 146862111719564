import useAuth from '../Hooks/useAuth'
import { toast } from 'react-toastify'
import { API_URL, TOAST_MSG_OPTIONS } from '../consts'

const client = async (url = '', method = 'POST', data = [], calculateHeight = true) => {
  let payload = data
  if (calculateHeight) {
    payload = data.map((item) => {
      const div = document.createElement('div')
      div.style.width = '450px'
      div.style.fontFamily = 'sans-serif'
      div.innerHTML +=
        '<h2 style="color: #e4001b; margin-top: 0;">INCIDENTS</h2>' +
        '<pre style="font-family: sans-serif; line-height: 19.6px; font-size: 16px; white-space: pre-line;" >' +
        item?.incidentFull +
        '</pre>'

      if (item?.sanction) {
        div.innerHTML +=
          '<div style="padding: 20px 0 10px;"><b>Action</b></div>' +
          '<pre style="font-family: sans-serif; line-height: 19.6px; font-size: 16px; white-space: pre-line;" >' +
          item.sanction +
          '</pre>'
      }

      if (item?.incidentOtherInfo) {
        div.innerHTML +=
          '<div style="padding: 20px 0 10px;"><b>Other Incident Info:</b></div>' +
          '<pre style="font-family: sans-serif; line-height: 19.6px; font-size: 16px; white-space: pre-line;" >' +
          item.incidentOtherInfo +
          '</pre>'
      }

      if (item?.dataType === 'partyNP' && item?.parties?.length) {
        item.parties.map((party) => {
          div.innerHTML +=
            '<div style="padding-bottom: 20px;"><div>Individual:<br /><b>' +
            party.firstName +
            ' ' +
            party.lastName +
            '</b></div><div>Profession:<b><br />' +
            party.profession +
            '<br />' +
            party.address +
            '<br />' +
            party.city +
            ', ' +
            party.state +
            ', ' +
            party.zip +
            '</b></div></div>' +
            '<div style="padding-bottom: 20px;"><div>License or Registration No.:<br /><b>' +
            party.licenseNo +
            '</b></div><div>License Type:<br /><b>' +
            party.licenseType +
            '</b></div><div>License Province:<br /><b>' +
            party.licenseState +
            '</b></div></div>'
        })
      }

      if (item?.dataType === 'partyP' && item?.parties?.length) {
        item.parties.map((party) => {
          div.innerHTML +=
            '<div style="padding: 20px 0;font-family: Helvetica; font-size: 16px;">Additional Name:</div>' +
            '<div style="font-family: Helvetica; font-size: 16px;">Name: ' +
            party.name +
            '</div>' +
            '<div style="font-family: Helvetica; font-size: 16px;">Profession: ' +
            party.profession +
            '</div>' +
            '<div style="font-family: Helvetica; font-size: 16px;">Company: ' +
            party.company +
            '</div>' +
            '<div style="font-family: Helvetica; font-size: 16px;">Location: ' +
            party.state +
            '</div>' +
            '<div style="font-family: Helvetica; font-size: 16px;">License or Registration No.: ' +
            party.licenseNo +
            '</div>'
        })
      }

      document.body.appendChild(div)

      const newItem = { ...item, height: div.clientHeight }

      div.parentNode.removeChild(div)

      return newItem
    })
  }

  const accessToken = useAuth()

  const options = {
    method,
    body: JSON.stringify(payload)
  }

  options.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    responseType: 'arraybuffer'
  }

  if (accessToken) {
    options.headers = {
      ...options.headers,
      Authorization: 'Bearer ' + accessToken
    }
  }

  const response = await fetch(`${API_URL}/${url}`, options)
  const content = await response.arrayBuffer()

  if (response.status >= 200 && response.status <= 299) {
    toast.success(`${response.status}- PDF generated successfully`, TOAST_MSG_OPTIONS)

    return content
  } else {
    toast.error(`${response.status}- Something went wrong`, TOAST_MSG_OPTIONS)

    return response
  }

  // const rawResponse = await fetch(`${API_URL}/${url}`, options)
  // const content = await rawResponse.arrayBuffer()

  // return content
}

export default client
