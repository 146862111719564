import { Navigate } from 'react-router-dom'
import useAuth from '../Hooks/useAuth'
import { APP_KEY } from '../consts'

export default function PrivateRoute({ component }) {
  const isAuthenticated = !!useAuth()

  if (!isAuthenticated) {
    const { pathname } = window.location
    localStorage.setItem(APP_KEY, JSON.stringify({ pathname }))
  }

  return isAuthenticated ? component : <Navigate to="/login" />
}
