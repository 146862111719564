import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Tabs, Tab } from '@mui/material'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role="tabpanel" hidden={value !== index} id={`redx-tabpanel-${index}`} aria-labelledby={`redx-tab-${index}`} {...other}>
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

function a11yProps(index) {
  return {
    id: `redx-tab-${index}`,
    'aria-controls': `redx-tabpanel-${index}`
  }
}

export default function TabsPans({ tabs, pans, selected = 0 }) {
  const [value, setValue] = useState(selected)
  useEffect(() => {
    setValue(selected)
  }, [selected])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="redx tabs">
          {tabs.map(({ label, id }, i) => (
            <Tab key={id} label={label} {...a11yProps(i)} />
          ))}
        </Tabs>
      </Box>

      {pans.map(({ component, id }, i) => (
        <TabPanel key={id} value={value} index={i}>
          {component}
        </TabPanel>
      ))}
    </Box>
  )
}
