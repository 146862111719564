import client from './apiClient'
import useToken from '../Hooks/useToken'

export const login = (data) => {
  return client().post('auth/login', data, { noAuthRequired: true })
}

export const forgotPassword = (data) => {
  return client().post('auth/forgot-password', data, { noAuthRequired: true })
}

export const resetPassword = (data) => {
  return client().post('auth/login/reset-password', data, { noAuthRequired: true })
}

export const getAuthStatus = () => {
  return client().get('auth/tokenStatus')
}

export const logout = () => {
  const token = useToken()
  return client().post('auth/logout', { sessionNo: token?.sessionNo })
}
