import useAuth from './useAuth'

export default function useToken() {
  const token = useAuth()

  try {
    const parsedToken = JSON.parse(atob(token.split('.')[1]))
    for (let key in parsedToken) {
      parsedToken[key] = typeof parsedToken[key] === 'string' ? parsedToken[key].replace(/\s+/g, ' ').trim() : parsedToken[key]
    }
    return parsedToken
  } catch (e) {
    return {}
  }
}
