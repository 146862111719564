import TermsAndConditionsLink from '../../Components/TermsAndConditionsLink'

export const privacyPolicy = (dialog) => [
  {
    variant: 'h5',
    component: 'div',
    color: 'primary',
    text: 'REDX CORP. Privacy Policy'
  },
  {
    variant: 'p',
    component: 'div',
    text: '(Updated September 2022)'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'Our team is committed to protecting the privacy and confidentiality of your personal documents and information'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'By using or accessing the REDX Service (“Service”), regardless of how accessed, you signify your agreement to be legally bound by this Privacy Policy and our Legal Terms and Conditions (the “Policies”). The Policies apply to all Subscribers, Users, and others who access or use the Service (referred to herein as “<b>you</b>”).'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'REDX CORP. (collectively, “<b>REDX CORP.</b>”, “<b>we</b>”, “<b>us</b>” or “<b>our</b>”) is committed to protecting the privacy and confidentiality of personal information in our possession. By using our website (“<b>Site</b>”) and mobile application (“<b>Application</b>”), you are accepting the practices described in this Privacy Policy. This Privacy Policy explains how we collect, use and disclose personal information that you provide to us through the REDX Service.'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'By using this Site and Application, you consent to the use of the information that you provide to us in accordance with this Privacy Policy.  We reserve the right, at our discretion, to amend this Privacy Policy at any time without prior individual notice. The date on which this Privacy Policy was last amended is shown at the top of this policy. Your continued use of the Site and Application will be deemed your agreement and consent that your information may be used in accordance with the new policy. You are responsible for verifying whether any amendments have been made and therefore we ask that you periodically check the date and review this Privacy Policy for the latest information on our privacy practices.'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'Website and Mobile Application Privacy Notice'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'Please read this Policy carefully to fully understand how we collect, share and protect information about you. This Policy is incorporated into and is a part of the Legal Terms and Conditions for this Site. Unless otherwise defined herein, all defined terms have the same meaning as set out in the Legal Terms and Conditions.'
  },
  {
    variant: 'p',
    component: 'span',
    text: 'To access the Terms of Service, click here '
  },
  {
    variant: 'el',
    text: <TermsAndConditionsLink dialog={dialog} />
  },
  {
    variant: 'p',
    component: 'div',
    text: 'By accessing and using the Site and Application, you agree that you have read and understand this Privacy Policy and that you accept and consent to the privacy practices and uses and disclosures of your information as described herein.'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'In order to deliver personalized and relevant information to you through our Site and Application, we collect certain information from users.'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'Information We Collect'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'While using the Service, we collect or will ask you to provide us with certain personal information.'
  },
  {
    variant: 'p',
    component: 'i',
    text: '<b>Account Information</b>'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'We collect and associate with your account the information you provide to us when you register for an account. You must provide the name of your employer, your first and last name and email address (“<b>Registration Information</b>”) to create an “<b>Account</b>”.'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'As part of the Account registration process, you will be required to create a log-in, which consists of your email address and a password (“User Credentials”). This password may at times be provided to you. You are responsible for keeping your password secret and secure. You should not disclose your password to anyone else, and you cannot share your User Credentials with others. You should select a strong, secure password that is different from the password you use with other accounts or social media sites.'
  },
  {
    variant: 'p',
    component: 'i',
    text: '<b>Device Information</b>'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'We may collect information about the device(s) you use to access our Service, including the hardware models, device IP address, operating systems and versions, software, file names and versions, preferred languages, unique device identifiers, advertising identifiers, serial numbers, device motion information, and mobile network information.'
  },
  {
    variant: 'p',
    component: 'div',
    text: '<b>Usage Data</b>'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'We may also collect information on how the Service is accessed and used by Authorized Users and Designated Users. These access logs include, but are not limited to, information such as the following: your machine’s TCP/IP address, your username (if applicable), files accessed, the date and time of such access, and other information pertaining to your activities on our website. These logs are used solely for performance, site administration and security reviews.'
  },
  {
    variant: 'p',
    component: 'i',
    text: '<b>Cookies</b>'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your web browser (if you allow) that enables the sites or service provider’s systems to recognize your browser and capture and remember certain information.'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'We may use cookies to help us remember, understand, and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. We may contract with third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'If you prefer, you can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies via your browser settings. Disabling cookies when you visit our Website or Application may disable some of our Services. Please consult your browser’s Help Menu for instructions.'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'Purposes for Collection and Use of Your Information'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'We may collect and use personal information for the purposes identified at the time of collection, as otherwise permitted or required by applicable law, or for any of the following purposes:'
  },
  {
    variant: 'p',
    component: 'ul',
    text:
      '<li>To establish, maintain and manage our relationship with you so that we can provide you with the Service;</li>' +
      '<li>To provide you with information related to the Service;</li>' +
      '<li>To fulfil or meet the reason you provided the information;</li>' +
      '<li>To respond to law enforcement requests and as required by applicable law, court order or governmental regulations;</li>' +
      '<li>To develop and manage our business and operations;</li>' +
      '<li>For billing, accounting and debt collection services relating to the Service;</li>' +
      '<li>To respond to your questions and concerns;</li>' +
      '<li>To detect and protect us and you from error, negligence, breach of contract, fraud, theft or other illegal activity, and which may include sharing personal information with our third party service providers;</li>' +
      '<li>To monitor and conduct an analysis of our Site or Application traffic and usage patterns and;</li>' +
      '<li>For internal, external and regulatory audit purposes.</li>'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'We will not use personal information we collected for materially different, unrelated, or incompatible purposes without providing you with notice and/or updating this Privacy Policy.'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'How Your Information is Disclosed or Shared'
  },
  {
    variant: 'p',
    component: 'i',
    text: '<b>How "we" share your information</b>'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'We do not sell client lists or personal information to others.'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'We may disclose personal information in the following circumstances:'
  },
  {
    variant: 'p',
    component: 'ul',
    text:
      '<li>As described in this Privacy Policy;</li>' +
      '<li>To investigate suspected fraud, harassment or other violations of any law, rule or regulation, or the policies for the Service;</li>' +
      '<li>To meet or comply with any applicable law, regulation, legal process, audit or enforceable request from a government agency or regulatory body;</li>' +
      '<li>In connection with the sale, merger or change of control of REDX CORP. In the event of a merger, acquisition sale or reorganization, or the potential thereof, we may disclose information about you to the extent the personal information is necessary to carry on the business or activity that was the object of the transaction. In the event of a completed transaction, notice will be given to affected users.</li>' +
      '<li>We may retain third party service providers, to help us provide the Service to you. We may need to transfer your personal information to them. We use contractual means to ensure these providers provide information security and privacy safeguards that are comparable to our own while your personal information is in their possession. These service providers make no independent use of your personal information.</li>'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'Links to Third Party Websites and Mobile Applications'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'As described in the Terms of Service, our Service may contain links to other third party sites. This Privacy Policy applies only to personal information that we collect, use and disclose. We are not responsible or liable for the privacy practices of third parties whose links you may click on within our Service, and we strongly recommend that you review any third party’s Privacy Policy before disclosing any personal information to a third party.'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'Retention and Destruction of Personal Information'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'REDX CORP. will store personal information collected via the Service for only as long as is necessary for the purposes for which the information was collected. Should you terminate the use of the REDX Service, we may retain your information to comply with our legal obligations, to resolve disputes, prevent fraud and abuse and to enforce our agreements, or as necessary for archival/audit purposes. Please note that your information may remain in back-up storage for some period of time.'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'Safeguarding Personal Information'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'We employ reasonable physical, electronic and procedural safeguards that are designed to protect personal information under our control against unauthorized access and use.'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'We may engage third party external servers as well as other external services, including cloud services and cloud compatible servers.  We try to ascertain to the best of our capabilities that any third-party service provider provides a level of safeguards that correspond with our own.'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'Electronic Communications'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'Email is our preferred method of communicating with you, although from time to time we may use other electronic means such as text messaging or telephone. We send emails to you based on the Registration Information you provide us upon account opening. These emails, and any other electronic communications, may contain personal information. By using the service you consent to receiving such communications.'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'While we make every effort to secure all our electronic communications, there are inherent risks with exchanging information electronically. For example, such information may be accidentally or deliberately intercepted by third parties. We do not accept any responsibility or liability for any damages you may suffer as a result of the interception, alteration or misuse of any information during any electronic exchange.'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'Keeping Information Accurate'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'It is important that your personal information held by us is accurate and complete so that we can give you better service. We ask that you keep us informed of any changes, such as a change of address, telephone number or email address.'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'You have the right to access, update, and correct the information we have about you after we have received your written request and authenticated your identity. Subject to legal restrictions or specific rights of refusal, we will inform you of the existence, use and disclosure of personal information relating to you and give you access to that information.'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'Despite our efforts, errors sometimes do occur. If you identify any personal information that is out-of-date, incorrect, or incomplete, let us know. If we agree, we will make the corrections.'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'Withdrawal of Consent'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'You may terminate your account as per the terms of your agreement with REDX CORP. or in accordance with REDX CORP’S Legal terms and Conditions as posted on the Site.'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'Privacy Concerns'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'If you have any questions and concerns relating to our handling of your personal information, please direct them to our privacy officer listed below. We may need to ask you for personal information to verify your identity.'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'E-mail: <a href="mailto:support@redx.ca">support@redx.ca</a>'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'If, having shared your concerns with us, you are not satisfied with our answers, you may file a complaint with a privacy regulator, such as the Office of the Privacy Commissioner of Canada by mail at 30 Victoria Street Gatineau, Quebec K1A 1H3 or by calling 1 800 282 1376.'
  },
  {
    variant: 'h5',
    component: 'div',
    text: 'Contacting Us'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'We welcome any queries, comments, complaints or requests you may have regarding this Privacy Policy.  Please do not hesitate to contact us at:'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'REDX CORP.<br />100 King Street West, Suite 5700<br />Toronto, ON. M5X 1C7'
  },
  {
    variant: 'p',
    component: 'div',
    text: 'E-mail: <a href="mailto:support@redx.ca">support@redx.ca</a>'
  }
]
