import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { AppBar as MuiAppBar, Button, Toolbar, Typography } from '@mui/material'

export default function Footer({ open, width }) {
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open'
  })(({ theme, open }) => ({
    backgroundColor: theme.palette.dark.lighter,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    ...(open && {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${width}px)`,
        marginLeft: `${width}px`
      },
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    })
  }))

  const getCurrentYear = () => {
    return new Date().getFullYear()
  }

  return (
    <AppBar
      position="relative"
      open={open}
      component="footer"
      sx={{
        boxShadow: 0,
        top: 'auto',
        bottom: 0,
        flexGrow: 1
      }}
    >
      <Toolbar
        sx={{
          alignItems: 'flex-start',
          '&.MuiToolbar-root': {
            minHeight: 48
          },
          '& .MuiButton-root': {
            p: 0,
            mr: 2,
            lineHeight: 1.5,
            color: (theme) => theme.palette.grey[600],
            fontWeight: 400,
            fontSize: 12,
            textTransform: 'capitalize'
          }
        }}
      >
        <Button component={Link} disableRipple disableFocusRipple disableElevation to="/terms-and-conditions">
          Terms &amp; Conditions
        </Button>
        <Button component={Link} disableRipple disableFocusRipple disableElevation to="/privacy-policy">
          Privacy policy
        </Button>
        <Typography
          component="div"
          sx={{
            color: (theme) => theme.palette.grey[600],
            fontWeight: 400,
            fontSize: 12,
            flexGrow: 1,
            textAlign: 'right'
          }}
        >
          Copyright &copy; 2003-{getCurrentYear()} REDX CORP. Services Inc. and its suppliers. All rights reserved
        </Typography>
      </Toolbar>
    </AppBar>
  )
}
