import { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'axios-progress-bar/dist/nprogress.css'
import { getAuthStatus } from './Services/auth'
import useAuth from './Hooks/useAuth'
import redxTheme from './Assets/redxTheme'
import GlobalContextProvider from './Context/GlobalContextProvider'
import PrivateRoute from './Components/PrivateRoute'
import PublicRoute from './Components/PublicRoute'
import DefaultLayout from './Layouts/DefaultLayout'
import AuthLayout from './Layouts/AuthLayout'
import NotFoundPage from './Pages/NotFoundPage'
import Login from './Pages/Login'
import Home from './Pages/Home'
import IndividualSearch from './Pages/IndividualSearch'
import CompanySearch from './Pages/CompanySearch'
import BatchSearch from './Pages/BatchSearch'
import RegistryByCompany from './Pages/RegistryByCompany'
import RegistryByIndividual from './Pages/RegistryByIndividual'
import GeneralSettings from './Pages/GeneralSettings'
import ForgotPassword from './Pages/ForgotPassword'
import ResetPassword from './Pages/ResetPassword'
import ContactUs from './Pages/ContactUs'
import FAQpage from './Pages/FAQ'
import TermsAndConditions from './Pages/TermsAndConditions'
import PrivacyPolicy from './Pages/PrivacyPolicy'

function App() {
  useEffect(() => {
    const hasToken = !!useAuth()
    if (hasToken) getAuthStatus()
  }, [])

  return (
    <div className="App">
      <ThemeProvider theme={redxTheme}>
        <CssBaseline />
        <GlobalContextProvider>
          <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/*" element={<PrivateRoute component={<DefaultLayout />} />}>
              <Route path="" element={<Home />} />
              <Route path="individual-search" element={<IndividualSearch />} />
              <Route path="company-search" element={<CompanySearch />} />
              <Route path="batch-search" element={<BatchSearch />} />
              <Route path="registry-by-company" element={<RegistryByCompany />} />
              <Route path="registry-by-individual" element={<RegistryByIndividual />} />
              <Route path="settings" element={<GeneralSettings />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="faq" element={<FAQpage />} />
              <Route path="terms-and-conditions" element={<TermsAndConditions />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
            </Route>
            <Route path="/*" element={<PublicRoute component={<AuthLayout />} />}>
              <Route path="login" element={<Login />} />
              <Route path="forgot-password" element={<ForgotPassword />} />
              <Route path="reset-password" element={<ResetPassword />} />
            </Route>
          </Routes>
        </GlobalContextProvider>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </ThemeProvider>
    </div>
  )
}

export default App
