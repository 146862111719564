export default function useQuery(data) {
  const queryArray = Object.keys(data).flatMap((key) => {
    if (data[key]) {
      if (typeof data[key] === 'string') return `${key}=${encodeURIComponent(data[key].trim())}`
      else return `${key}=${data[key]}`
    }
    return []
  })
  return queryArray.join('&')
}
