import client from './apiClient'

export const getUser = (id) => {
  return client().get(`users/${id}`)
}

export const updateUser = ({ id, data }) => {
  return client().patch(`users/${id}`, data)
}

export const changePassword = (data) => {
  return client().patch('users/change-password', data)
}

export const contactUs = (data) => {
  return client().post('supports', data)
}
