import { useContext, useEffect, useState } from 'react'
import { parseISO } from 'date-fns'
import { DataGrid } from '@mui/x-data-grid'
import { useMediaQuery } from '@mui/material'
import { useCamelToCapitalization } from '../Hooks/useCaseConversion'
import { GlobalContext } from '../Context/GlobalContextProvider'

export default function DataTable({ rows = [], callback, selectionModel, columnVisibilityModel = {} }) {
  const hideableCols = {
    sl: false,
    profession: false,
    incident: false,
    dataType: false,
    fines: false,
    ...columnVisibilityModel
  }
  const [visibilityModel, setVisibilityModel] = useState(hideableCols)

  const { loader } = useContext(GlobalContext)

  const [pageSize, setPageSize] = useState(10)
  const [columns, setColumns] = useState([])
  useEffect(() => {
    if (rows.length && Object.keys(visibilityModel).length) {
      const cols = Object.keys(rows[0]).map((field) => ({
        field,
        headerName: useCamelToCapitalization(field),
        minWidth: field === 'name' ? 150 : 100,
        flex: field === 'name' ? 1 : 0.5,
        type: field.toLowerCase().includes('date') ? 'date' : 'string',
        valueGetter: ({ value }) => {
          if (!value) return value
          if (field.toLowerCase().includes('date')) return parseISO(value)
          else if (typeof value === 'string') return value.toUpperCase()
          else return value
        },
        hideable:
          ['profession', 'licenseNo'].some((f) => f === field) ||
          (Object.keys(hideableCols).some((f) => f === field) ? hideableCols?.[field] : true)
      }))
      setColumns(cols)
    }
  }, [rows, visibilityModel])

  const getRowId = ({ sl }) => sl

  const NoRowsOverlay = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <p>No Results</p>
      </div>
    )
  }

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'))

  return (
    <div style={{ height: 'calc(100% - 72px)', minHeight: mdUp ? 400 : 500, width: '100%' }}>
      <DataGrid
        sx={{
          display: 'flex',
          flexDirection: 'column-reverse',
          '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
            display: 'none'
          }
        }}
        onSelectionModelChange={callback}
        selectionModel={selectionModel}
        getRowId={getRowId}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        checkboxSelection
        components={{
          NoRowsOverlay
        }}
        loading={loader}
        columnVisibilityModel={visibilityModel}
        onColumnVisibilityModelChange={(newModel) => {
          console.log('onColumnVisibilityModelChange', newModel)
          setVisibilityModel(newModel)
        }}
      />
    </div>
  )
}
